



















import { Component, Vue } from "vue-property-decorator";
import HomeTitle from "@/components/HomeTitle.vue";
import HomeFooter from "@/components/HomeFooter.vue";

@Component({
  components: {
    HomeFooter,
    HomeTitle,
  },
})
export default class Home extends Vue {}
